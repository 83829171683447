<template>
  <div class="pdf-doc">
    <div class="pdf-view">
      <!-- Ficha Tecnica -->
      <section class="pdf">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo" />
          </div>
          <div class="title-container">
            <div>
              <strong>
                {{ titleCompany }}
              </strong>
            </div>
            <div>
              <strong>
                {{ titleVessel }}
              </strong>
            </div>
            <div>
              <strong>
                {{ titleFromItinerary }}
              </strong>
            </div>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>

        <section class="body">
          <div class="Preview" v-if="Preview">
            {{ $t('button.preview') }}
          </div>
          <div class="general-info">
            <div class="info-content">
              <div class="title-report">
                <table class="title-table">
                  <tbody>
                    <tr>
                      <td class="limit-field-16 text-size-12">
                        <strong>{{ `${$t('label.line')}: ` }} {{infoContainer?.ShippingLineName}} </strong>
                        {{ }}
                      </td>
                      <td class="limit-field-16">
                      </td>
                      <td class="text-center">
                        <strong style="font-size: medium;">{{ $t('label.EquipmentInterchangeReceipt') }}</strong>
                      </td>
                      <td style class="limit-field-16">
                      </td>
                      <td class="limit-field-16 text-size-12">
                        <strong class="color-eir">N° {{infoContainer?.YardCargoEirCode}} </strong>
                      </td>
                     </tr>
                  </tbody>
                </table>
              </div>
              
              
            </div>
          </div>
        </section>

        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="corners-container">
            <p>
              {{ $t("label.page")+': '+(0) }} {{ $t("label.of") }} {{ 0 }}
            </p>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DateFormater, NumberFormater, trimRemoveSpecialCharacters } from '@/_helpers/funciones';
import DataStorage from "@/_helpers/localStorage";

//Data
function data() {
  return {
    baysTwenty: [],
    baysForty: [],
  };
}

//Created:
function created() {
  this.$i18n.locale = DataStorage.getLocale() || "es";
}

//Metodos:
function formatNumber(number, decimal) {
  return NumberFormater.formatNumber(number, decimal);
}

function DateFormat(Date) {
  if (Date != null && Date != '') {
    return DateFormater.formatDateTimeWithSlash(Date);
  } else {
    return 'N/A';
  }
}

function DateFormat2(Date) {
  if (Date != null && Date != '') {
    return DateFormater.formatDateWithSlash(Date);
  } else {
    return 'N/A';
  }
}

function RemoveCharacters(item, limit) {
  if (item.trim().length > limit) {
    return trimRemoveSpecialCharacters(item, limit);
  }else{
    return item
  }
 
}

//Computeds:
function hasLogo() {
  return `${this.$store.getters["connection/getBase"]}${
    this.user.LogoRoute
      ? this.user.LogoRoute.replace("Public/", "", null, "i")
      : ""
  }`;
}

function currentDateTime() {
  return DateFormater.formatTimeZoneToDateTime(new Date());
}

function titleCompany() {
 let CompanyName = this.user.CompanyName;
 let CompanyRif = this.user.CompanyRif;
 let BranchName = this.user.BranchJson[0].BranchName;

  return `${CompanyName} ${CompanyRif} - ${BranchName}`;
}

function titleVessel() {
 let VesselName = '';
 let Imo = '';
 let CountryName = '';
 let berth = this.$t('label.berth');
 let BerthName = '';

  return `${VesselName} ${Imo}, ${CountryName}, ${berth}: ${BerthName}`;
}

function titleFromItinerary() {
  let previousDate = '';
  let laterDate = '';
  let fecha = "";
  let voyage = this.$t('label.voyage')+": ";

  return `${previousDate}, ${laterDate} - ${voyage}`;
}

function loginName() {
  return this.user ? `${this.$t("label.USUARIO")}: ${this.user.Login}` : "";
}

function totalPages() {
  return 1;
}

export default {
  name: "eir-report",
  props: {
    Condition: Number,
    TpVessel: String,
    CraneName: String,
    Header: Object,
    Embarked: Object,
    Movement: Object,
    Lapse: Array,
    Position: Array,
    ChartHold: Array,
    ChartTon: Array,
    itinerarySelected: Object,
    VehicleName: String,
    infoContainer: {
      type: Object,
      default: () => {}
    },
    OperatorName: String,
    ClientName: String,
    FormatedDate: String,
    TransportName: String,
    DriverName: String,
    Devolution: String,
    ObservationUser: String,
    FgEirContainerCondiction: Boolean,
    arrIdsDamage: Array,
    arrIdsAccessories: Array,
    Pages: Number,
    Preview: Boolean,
  },
  mixins: [],
  components: {
    SumaryChartHold,
    SumaryChartTon,
  },
  data,
  created,
  methods: {
    DateFormat,
    DateFormat2,
    formatNumber,
    RemoveCharacters,
  },
  computed: {
    currentDateTime,
    titleCompany,
    titleVessel,
    titleFromItinerary,
    hasLogo,
    loginName,
    totalPages,
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  watch: {},
};
</script>
<style src="./pdf-styles.css"></style>