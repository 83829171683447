<template>
  <transition name="fade">
    <div class="c-app pdf-page">
      <CertificateOfEntryReportPdf
      
      />
    </div>
  </transition>
</template>

<script>
import CertificateOfEntryReportPdf from "./certificate-of-entry-report";

//Data
function data() {
  return {
    pageInfo: {},
    Pages: 1,
  };
}

//Created
async function mounted() {
  let info = JSON.parse(localStorage.getItem("pageInfo"));

  if (!info) {
    window.close();
    return;
  }

  this.pageInfo = info;

  setTimeout(() => {
    //window.print();
  }, 250),
 
  localStorage.removeItem("pageInfo");
  
  window.addEventListener("afterprint", function(event) {
    close();
  });
}



//Methods:


export default {
  name: "Report-Certificate-Of-Entry",
  components: {

  },
  data,
  mounted,
  methods: {

  },
  computed: {},
};
</script>

<style scoped>
.pdf-page {
  width: 100%;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  z-index: 2;
}
</style>